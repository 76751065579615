<template>
  <el-row class="team-profile">
    <el-row class="go-back">
      <el-button @click="$router.push('/squad-management')" class="go-back-button" icon="el-icon-arrow-left">
        Go Back
      </el-button>
    </el-row>
    <div v-if="currentTeam" class="box-card" id="squad-management">
      <div class="team-detail">Name: {{ currentTeam.name || "" }}</div>
      <div class="team-detail">Age Level: {{ currentTeam.ageLvl || "" }}</div>
      <div class="team-detail">Member Type: {{ teamMemberType }}</div>
      <br />
      <el-tabs type="card">
        <el-tab-pane label="Players" v-if="currentTeam.memberType !== 'referee'">
          <el-row class="filter-box">
            <el-col :span="20" class="filter">
              <span> Filter by: </span>
              <el-input autocomplete="off" clearable v-model="filterName" placeholder="Name">
              </el-input>
              <el-select clearable v-model="filterGender" placeholder="Gender">
                <el-option v-for="item in ['Male', 'Female']" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <el-select clearable v-model="filterAge" placeholder="Age level">
                <el-option v-for="item in ageLevels.map((l) => l.name)" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="tw-pt-4">
            <el-transfer class="player-squad" v-model="squadPlayers" :props="{
              key: 'memberId',
              label: 'name',
            }" :button-texts="['Remove From Squad', 'Add To Squad']" :titles="['Club Members', 'Current Squad']"
              :data="possiblePlayers">
            </el-transfer>
          </el-row>
          <el-row :gutter="10" class="update-squad-box">
            <el-button type="success" class="update-squad" @click="updateSquad('squadPlayers')">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Non-Players" :disabled="!isSuperAdminTFA && currentTeam.expired">
          <el-row type="flex" :gutter="10">
            <el-col :span="8" class="add-non-player">
              <el-form label-position="top" :model="addNP" :rules="npteamListRules" ref="npteamListRules">
                <el-form-item prop="role" v-if="!isTfa">
                  <el-select v-model="addNP.role" placeholder="Role" value-key="name" clearable filterable>
                    <el-option v-for="(item, index) in nonPlayerRoles" :key="index.type" :label="item.name"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="role" v-else>
                  <el-select v-model="addNP.role" placeholder="Role" value-key="name" clearable filterable>
                    <el-option v-for="(item, index) in squadRoles" :key="index.type" :label="item.name" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="tw-mt-4" prop="memberId">
                  <el-select v-model="addNP.memberId" placeholder="Member" clearable filterable>
                    <el-option v-for="(item, index) in possibleNonByRole" :key="index"
                      :label="`${item.firstName} ${item.lastName}`" :value="item.memberId">{{ `${item.firstName}
                      ${item.lastName}` }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-button class="tw-mt-4" type="success" @click="addNonPlayer">
                Add To Squad
              </el-button>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <data-tables :data="squadNon" :table-props="tableProps" :page-size="pageSize" @row-click="removeNonplayer"
              id="np-teamlist" class="data-table">
              <el-table-column fixed prop="memberId" label="Id" width="100"> </el-table-column>
              <el-table-column prop="firstName" label="First Name"> </el-table-column>
              <el-table-column prop="lastName" label="Last Name"> </el-table-column>
              <el-table-column :formatter="roleFormatter" prop="type" label="Role">
              </el-table-column>
              <el-table-column align="center" prop="" label="Actions">
                <template>
                  <i class="el-icon-delete remove-icon"></i>
                </template>
              </el-table-column>
            </data-tables>
          </el-row>
          <el-row :gutter="10">
            <el-button type="success" class="update-squad" @click="updateSquad('squadNon')">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Player Points"
          v-if="clearance === roles.associationAdmin && currentTeam.memberType !== 'referee'"
          :disabled="!isSuperAdminTFA && currentTeam.expired">
          <player-points :team="currentTeam" :squadPlayers="squadPlayers" />
        </el-tab-pane>
        <el-tab-pane label="Default Squad" v-if="competitionId" :disabled="!isSuperAdminTFA && currentTeam.expired">
          <default-squad :team="currentTeam" :teamid="currentTeam._id" :positions="availablePositions"
            :possibles="possibles" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-row>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import PlayerPoints from "./SquadManagementPlayerPointsBeta.vue";
import DefaultSquad from "./DefaultSquad.vue";
import {
  orgtree,
  errormsg,
  ageLevels,
  memberTypes,
  roles,
  currentSeason,
  nonPlayerRoles,
  nonPlayerRolesTFA,
} from "../../utils/constants";
import { calculateAge } from "../../utils/utilities";

export default {
  name: "SquadManagementDetail",
  components: {
    PlayerPoints,
    DefaultSquad,
  },
  props: {},
  data() {
    return {
      squadRoles: nonPlayerRoles,
      ageLevels,
      currentTeam: {},
      errormsg,
      filterGender: "",
      filterName: "",
      filterRole: "",
      teamAgeLvl: "",
      loading: true,
      orgtree,
      roles,
      tableProps: {
        border: true,
      },
      pageSize: 100,
      memberTypes,
      nonPlayerRoles,
      nonPlayerRolesTFA,
      addNP: {
        memberId: null,
        role: null, //{  "_id": 81776191, "role": { "name": "Trainer 2", "type": "trainer-2", "memberType": "trainer" } }
      },
      npteamListRules: {
        memberId: { required: true, message: errormsg.select_option, trigger: "blur" },
        role: { required: true, message: errormsg.select_option, trigger: "blur" },
      },
      newNP: [],
      possibles: [],
      squadPlayers: [],
      squadNon: [],
      competitionId: null,
      availablePositions: [],
    };
  },

  mounted() {
    const { id } = this.$route.params;
    this.$store.commit("app/loading", true);
    this.squadRoles = this.isTfa ? nonPlayerRolesTFA : nonPlayerRoles;

    const url = this.isTfa
      ? `/nrl/api/v1/admin/squad-tfa/teamonly/${id}`
      : `/nrl/api/v1/admin/squad/teamonly/${id}`;
    this.$http
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data && res.data.possibles && res.data.possibles.length > 0) {
            this.possibles = res.data.possibles.map((p) => {
              const formatDOB = p.dob.split("-");
              const permit = p.permitId ? "(permit)" : "";
              p.name = `${p.memberId} ${p.firstName} ${p.lastName} ${p.role} ${p.memberType} ${formatDOB[2]}/${formatDOB[1]}/${formatDOB[0]} ${permit}`;
              return p;
            });
          }
          this.currentTeam = res.data.team;
          if (this.currentTeam.memberType === "referee")
            this.squadRoles = this.squadRoles.filter((s) => s.memberType === "referee");
          this.squadPlayers = this.currentTeam.squadPlayers
            ? this.currentTeam.squadPlayers.map((p) => p.memberId)
            : [];
          this.squadNon = this.currentTeam.squadNon ? this.currentTeam.squadNon : [];
          this.$http.get(`/nrl/api/v1/admin/teams/${id}/competition`).then((compId) => {
            if (compId && compId.data && compId.data.data[0])
              this.competitionId = compId.data.data[0]._id;
          });
        }
      })
      .catch((error) => {
        const msg = error?.response?.data?.message ? error.response.data.message : "Error";
        this.$store.commit("app/loading", false);
        return this.$customError(msg);
      });

    this.$store.commit("app/loading", false);
  },
  methods: {
    roleFormatter(nonPlayer) {
      const [roleName] = this.squadRoles.filter((s) => s.type === nonPlayer?.role);

      return roleName && roleName.name ? roleName.name : "";
    },
    clearFilters(e) {
      this.filterName = "";
      this.filterGender = "";
      this.filterRole = "";
    },
    // formatters
    modifiedFormatter(row) {
      return row.modifiedAt ? moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },

    // update squad
    async updateSquad(squadType) {
      try {
        this.$store.commit("app/loading", true);
        const { _id: teamid } = this.currentTeam;
        const squad = this.squadPlayers.map((memberId) => {
          return this.possibles.find((p) => p.memberId === memberId);
        });

        if (squadType === "squadPlayers") {
          await this.$http.put(`/nrl/api/v1/admin/squad-beta/${teamid}`, {
            squadPlayers: squad,
            type: "player",
          });
        }
        if (squadType === "squadNon") {
          await this.$http.put(`/nrl/api/v1/admin/squad-beta/${teamid}`, {
            squadNon: this.squadNon,
            type: "nonplayer",
          });
        }

        this.$store.commit("app/loading", false);
        this.$customSuccess();
      } catch (error) {
        this.$store.commit("app/loading", false);
        this.$customError(_.get(error, "response.data.message"));
      }
    },
    // filters

    dofilterAge: (level, player) => {
      if (level && level !== "") {
        const age = calculateAge(player.dob, true);
        const { dispensations } = player;
        const rule = ageLevels.find((lvl) => lvl.name === level);
        const { min, max } = rule;
        const ageInBracket = level.includes("Under") ? age === max : age > min;
        if (ageInBracket) return true;
        if (dispensations && dispensations.length > 0) {
          return dispensations
            .map((d) => {
              if (
                d.season === currentSeason &&
                d.dispensated === true &&
                (d.rule.includes("above") || d.rule.includes("below"))
              ) {
                const { rule: thisrule } = d;
                const number = parseInt(thisrule.split("(")[1].split(")")[0]);
                const add = thisrule.split(" ").pop() === "above";
                const modifiedAge = add ? age + number : age - number;
                return modifiedAge >= min && modifiedAge <= max;
              }
              return false;
            })
            .some((r) => r === true);
        }
      }
      return false;
    },
    async addNonPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.npteamListRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { memberId, role } = this.addNP;
        const player = this.possibles.find(
          (p) => p.memberId === memberId && p.memberType === this.addNP.role.memberType
        );
        if (player === undefined) return;
        player.role = this.addNP.role.type;
        this.squadNon.push(player);

        this.$set(this.addNP, "memberId", null);
        this.$set(this.addNP, "role", null);
      } catch (e) {
        this.$customError("Could not add Non-Player");
      }
    },
    removeNonplayer(row) {
      this.$confirm(
        "Are you sure you want to remove this non-player from the squad list",
        "Remove Non-Player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(async () => {
          this.squadNon = this.squadNon.filter((s) => s.memberId !== row.memberId);
        })
        .catch((e) => {
          this.$customError("Could not remove non player. Please refresh your screen.");
        });
    },
    findMemberType(memberType) {
      let normalizedMemberType = (str) => str.toLowerCase().replace(/[-\s]+/g, ' ');

      let type = memberTypes.find((m) =>
        normalizedMemberType(m.type) === normalizedMemberType(this.currentTeam.memberType) ||
        normalizedMemberType(m.name) === normalizedMemberType(this.currentTeam.memberType)
      );

      return type ? normalizedMemberType(type.name) === normalizedMemberType(memberType) : false;
    }
  },
  computed: {
    possiblePlayers() {
      const pl = [];
      const unique = {};
      for (const p of this.possibles) {
        let pass = true;
        if (this.filterAge) pass = this.dofilterAge(this.filterAge, p);
        if (pass && this.filterName)
          pass = p.name.toLowerCase().includes(this.filterName.toLowerCase());
        if (pass && this.filterGender) pass = this.filterGender === p.gender;
        if (pass) pass = p.role === "player";
        if (pass) pass = this.findMemberType(p.memberType);
        if (pass && unique[`${p.memberId}`]) pass = false;
        if (pass) unique[`${p.memberId}`] = true;
        if (pass) pl.push(p);
      }
      return pl.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
        if (a.firstName.toLowerCase() == b.firstName.toLowerCase()) return 0;
      });
    },

    possibleNonByRole() {
      if (!this.addNP?.role?.type) return [];
      const pl = [];
      const unique = {};
      for (const p of this.possibles) {
        let pass = true;
        pass = p.memberType === this.addNP.role.memberType;
        if (pass && unique[`${p.memberId}`]) pass = false;
        if (pass) unique[`${p.memberId}`] = true;
        if (pass) {
          const found = this.squadNon.find((s) => s.memberId === p.memberId);
          if (found !== undefined) pass = false;
        }
        if (pass) pl.push(p);
      }
      return pl.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
        if (a.firstName.toLowerCase() == b.firstName.toLowerCase()) return 0;
      });
    },
    filterAge: {
      get() {
        return this.teamAgeLvl;
      },
      set(val) {
        this.teamAgeLvl = val;
      },
    },
    teamMemberType() {
      const type = memberTypes.find((m) => m.type === this.currentTeam.memberType || m.name === this.currentTeam.memberType);
      return type ? type.name : null;
    },
    nonPlayerNames() {
      return this.memberTypes.filter((p) => p.playing === false).map((p) => p.name);
    },

    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    isSuperAdminTFA() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 31;
    },
  },
  watch: {
    competitionId() {
      const compid = this.competitionId ? this.competitionId : null;
      if (!compid) return;
      this.$http
        .get(`/nrl/api/v1/admin/competitions-settings/competition/${compid}`)
        .then((settingRes) => {
          this.availablePositions = settingRes.data.data.positions;
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}

.go-back-button {
  float: left;
}

.team-detail {
  padding: 0.4rem;
}

.box-card {
  margin-top: 1rem;
  text-align: left;
}

.player-squad {
  margin-top: 2rem;
  text-align: center;
}

.update-squad-box {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.filter-box {
  padding-top: 2rem;
  padding-left: 3%;
  padding-bottom: 1rem;

  span {
    margin-right: 2rem;
  }

  .el-input {
    width: 195px;
    height: 40px;
  }

  .el-input,
  .el-select {
    margin-right: 3rem;
  }
}

.data-table {
  .captain-icon {
    color: green;
    font-size: 2rem;
  }

  .remove-icon {
    color: red;
    font-size: 2rem;
  }
}
</style>
