<template>
  <el-row class="team-profile">
    <el-row class="go-back">
      <el-button
        @click="$router.push('/squad-management')"
        class="go-back-button"
        icon="el-icon-arrow-left"
      >
        Go Back
      </el-button>
    </el-row>
    <div v-if="currentTeam" class="box-card" id="squad-management">
      <div class="team-detail">Name: {{ currentTeam.name || "" }}</div>
      <div class="team-detail">Age Level: {{ currentTeam.ageLvl || "" }}</div>
      <div class="team-detail">Member Type: {{ teamMemberType }}</div>
      <br />
      <el-tabs type="card">
        <el-tab-pane label="Players">
          <el-row class="filter-box">
            <el-col :span="20" class="filter">
              <span> Filter by: </span>
              <el-input autocomplete="off" clearable v-model="nameFilter" placeholder="Name">
              </el-input>
              <el-select clearable v-model="genderFilter" placeholder="Gender">
                <el-option
                  v-for="item in ['Male', 'Female']"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select clearable v-model="ageFilter" placeholder="Age level">
                <el-option
                  v-for="item in ageLevels.map((l) => l.name)"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-row class="tw-pt-4">
            <el-transfer
              @change="clearFilters"
              class="player-squad"
              v-model="squadAllocation.players"
              :props="{
                key: '_id',
                label: 'display_name',
              }"
              :button-texts="['Remove From Squad', 'Add To Squad']"
              :titles="['Club Members', 'Current Squad']"
              :data="availablePlayersFiltered"
            >
            </el-transfer>
          </el-row>
          <el-row :gutter="10" class="update-squad-box">
            <el-button type="success" class="update-squad" @click="updateSquad">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Non-Players">
          <!-- <el-row class="filter-box">
            <el-col :span="20" class="filter">
              <span> Filter by: </span>
              <el-input autocomplete="off" v-model="nameFilter" placeholder="Name" clearable>
              </el-input>
              <el-select clearable v-model="roleFilter" placeholder="Role">
                <el-option v-for="item in nonPlayerRoles" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="filter-box">
            <el-col :span="20" class="filter">
              <span> Squad Roles: </span>
              <el-select clearable v-model="squadRole" placeholder="Squad Roles">
                <el-option
                  v-for="(item, index) in squadRoles"
                  :key="index.type"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-transfer
              @change="clearFilters"
              class="player-squad"
              v-model="squadAllocation.nonplayers"
              :props="{
                key: '_id',
                label: 'display_name'
              }"
              :button-texts="['Remove From Squad', 'Add To Squad']"
              :titles="['Club Members', 'Current Squad']"
              :data="availableNPlayersFiltered"
            >
            </el-transfer>
          </el-row> -->
          <el-row type="flex" :gutter="10">
            <el-col :span="8" class="add-non-player">
              <el-form
                label-position="top"
                :model="addNP"
                :rules="npteamListRules"
                ref="npteamListRules"
              >
                <el-form-item prop="role">
                  <el-select
                    v-model="addNP.role"
                    placeholder="Role"
                    value-key="name"
                    @change="addNP._id = null"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in squadRoles"
                      :key="index.type"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="tw-mt-4" prop="_id">
                  <el-select v-model="addNP._id" placeholder="Member" clearable filterable>
                    <el-option
                      v-for="(item, index) in nonPlayerFilter(clubMember)"
                      :key="index"
                      :label="item.name"
                      :value="item._id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-button class="tw-mt-4" type="success" @click="handleAddNonPlayer">
                Add To Squad
              </el-button>
            </el-col>
            <!-- <el-col :span="12">
              <el-button type="success" @click="handleAddNonPlayer">
                Add To Squad
              </el-button>
            </el-col> -->
          </el-row>
          <el-row :gutter="10">
            <data-tables
              :data="currentSquad"
              :table-props="tableProps"
              :page-size="pageSize"
              @row-click="removeFromNonPlayerTeamList"
              id="np-teamlist"
              class="data-table"
            >
              <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
              <el-table-column label="First Name">
                <template #default="{ row }">
                  {{
                    row.profile.preferNameStatus
                      ? row.profile.preferFirstName || row.profile.firstName
                      : row.profile.firstName
                  }}
                </template>
              </el-table-column>
              <el-table-column label="Last Name">
                <template #default="{ row }">
                  {{
                    row.profile.preferNameStatus
                      ? row.profile.preferLastName || row.profile.lastName
                      : row.profile.lastName
                  }}
                </template>
              </el-table-column>
              <el-table-column :formatter="roleFormatter" prop="role" label="Role">
              </el-table-column>
              <el-table-column align="center" prop="" label="Actions">
                <template>
                  <i class="el-icon-delete remove-icon"></i>
                </template>
              </el-table-column>
            </data-tables>
          </el-row>
          <el-row :gutter="10">
            <el-button type="success" class="update-squad" @click="updateSquad">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Player Points" v-if="clearance === roles.associationAdmin">
          <player-points :team="currentTeam" :squadPlayers="squadPlayers" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-row>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import PlayerPoints from "./SquadManagementPlayerPoints.vue";
import {
  orgtree,
  errormsg,
  ageLevels,
  memberTypes,
  roles,
  currentSeason,
  nonPlayerRoles,
} from "../../utils/constants";
import { calculateAge } from "../../utils/utilities";

export default {
  name: "SquadManagementDetail",
  components: {
    PlayerPoints,
  },
  props: {},
  data() {
    return {
      roles,
      orgtree,
      errormsg,
      ageLevels,
      currentTeam: {},
      loading: true,
      nameFilter: "",
      genderFilter: "",
      roleFilter: "",
      teamAgeLvl: "",
      squadAllocation: {
        availablePlayers: [],
        availableNonPlayers: [],
        players: [],
        nonplayers: [],
      },
      tableProps: {
        border: true,
      },
      pageSize: 100,
      memberTypes,
      squadRoles: nonPlayerRoles,
      squadRole: null,
      nonplayers: [],
      addNP: {
        _id: null,
        role: null,
      },
      npteamListRules: {
        _id: { required: true, message: errormsg.select_option, trigger: "blur" },
        role: { required: true, message: errormsg.select_option, trigger: "blur" },
      },
      newNP: [],
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.$store.commit("app/loading", true);
    Promise.all([
      this.$http.get(`/nrl/api/v1/admin/teams/${id}`),
      this.$http.get(`/nrl/api/v1/admin/squad/players/${id}`),
      this.$http.get(`/nrl/api/v1/admin/squad/nonplayers/${id}`),
    ])
      .then(([team, playerRes, nonPlayerRes]) => {
        this.currentTeam = team.data.data;
        this.teamAgeLvl = this.currentTeam && this.currentTeam.ageLvl;

        const playersData = playerRes.data.data;
        const nonPlayersData = nonPlayerRes.data.data;
        const allPlayers = [...playersData.clubplayers, ...playersData.players];
        const allNonPlayers = [...nonPlayersData.clubnonplayers, ...nonPlayersData.nonplayers];
        const availablePlayers =
          allPlayers.length > 0 ? allPlayers.map((p) => this.formatWithName(p)) : [];
        const availableNonPlayers = allNonPlayers.length
          ? allNonPlayers.map((p) => this.formatWithName(p))
          : [];

        const players = playersData.players.length > 0 ? playersData.players.map((p) => p._id) : [];

        const nonplayers =
          nonPlayersData.nonplayers.length > 0 ? nonPlayersData.nonplayers.map((p) => p._id) : [];
        this.nonplayers = [...nonplayers];
        this.squadAllocation = {
          availablePlayers,
          availableNonPlayers,
          players,
          nonplayers,
        };
        this.$store.commit("app/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("app/loading", false);
      });
  },
  methods: {
    nonPlayerFilter(clubmembers) {
      if (!clubmembers || clubmembers.length < 1) return [];
      if (!this.addNP.role || !this.addNP.length < 1) return [];
      const filtered = clubmembers.filter((member) => {
        if (!member.activeRegos) return false;

        const entityId = this.$store.getters["user/activeRole"].entity._id;
        const regos = member.activeRegos.map((rego) => {
          if (
            rego.entityId === entityId ||
            (rego.entityOrgtree &&
              rego.entityOrgtree.association &&
              rego.entityOrgtree.association._id === entityId)
          ) {
            return rego.memberType;
          } else return;
        });

        return regos.indexOf(this.addNP.role.memberType) > -1;
      });
      return filtered;
    },
    clearFilters(e) {
      this.nameFilter = "";
      this.genderFilter = "";
      this.roleFilter = "";
    },
    // formatters
    modifiedFormatter(row) {
      return row.modifiedAt ? moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    formatWithName(pl) {
      const preferFirstName = pl.profile.preferFirstName || pl.profile.firstName;
      const preferLastName = pl.profile.preferLastName || pl.profile.lastName;

      const name = pl.profile.preferNameStatus
        ? `${preferFirstName} ${preferLastName}`
        : `${pl.profile.firstName} ${pl.profile.lastName}`;

      return { ...pl, name };
    },
    // update squad
    async updateSquad() {
      try {
        this.$store.commit("app/loading", true);
        const { players, nonplayers } = this.squadAllocation;
        const { _id: teamid } = this.currentTeam;
        const playerResponse = await this.$http.put(`/nrl/api/v1/admin/squad/${teamid}`, {
          players,
          type: "player",
        });
        const nonPlayerRes = await this.$http.put(`/nrl/api/v1/admin/squad/${teamid}`, {
          nonplayers,
          type: "nonplayer",
        });
        const updatePromise = [];

        if (this.newNP.length > 0) {
          this.currentSquad.forEach((nonplayer) => {
            if (this.newNP.indexOf(nonplayer._id) > -1) {
              updatePromise.push(
                this.$http.put(
                  `/nrl/api/v1/admin/members/nonplayerteams/${nonplayer._id}/${teamid}`,
                  {
                    squadRole: nonplayer.role,
                  }
                )
              );
            }
          });
        }

        await Promise.all(updatePromise);

        if (playerResponse.data.message) {
          this.$customError(playerResponse.data.message);
        }
        if (nonPlayerRes.data.message) {
          this.$customError(nonPlayerRes.data.message);
        }
        if (!playerResponse.data.message && !nonPlayerRes.data.message) {
          this.$customSuccess();
        }

        // Remove player assigned by super admin
        this.squadAllocation.availablePlayers = _.filter(
          this.squadAllocation.availablePlayers,
          (ap) =>
            !(ap.assignedBySuper === true && !_.includes(this.squadAllocation.players, ap._id))
        );

        this.$store.commit("app/loading", false);
      } catch (error) {
        this.$store.commit("app/loading", false);
        this.$customError(_.get(error, "response.data.message"));
      }
    },
    // filters
    doTypeFilter: (type, player, entityId) =>
      player.assignedBySuper
        ? true
        : player.activeRegos.find((r) => r.memberType === type && r.entityOrgtree.association._id
        === entityId),
    doNameFilter: (name, player) =>
      name ? player.name.toLowerCase().includes(name.toLowerCase()) : true,
    doRoleFilter: (role, player, entityId) => {
      if (role) {
        return (
          player.activeRegos.filter(
            (r) => r.memberType === role.toLowerCase() && r.entityId === entityId
          ).length > 0 ||
          player.activeRegos.filter(
            (r) => r.permit === true && r.type === role.toLowerCase() && r.entityId === entityId
          ).length > 0
        );
      }
      return true;
    },
    doGenderFilter: (gender, player) => (gender ? player.profile.gender === gender : true),
    doAgeFilter: (level, player) => {
      if (level && level !== "") {
        const age = calculateAge(player.profile.dob, true);
        const { dispensations } = player;
        const rule = ageLevels.find((lvl) => lvl.name === level);
        const { min, max } = rule;
        const ageInBracket = level.includes("Under") ? age === max : age > min;
        if (ageInBracket) return true;
        if (dispensations && dispensations.length > 0) {
          return dispensations
            .map((d) => {
              if (
                d.season === currentSeason &&
                d.dispensated === true &&
                (d.rule.includes("above") || d.rule.includes("below"))
              ) {
                const { rule: thisrule } = d;
                const number = parseInt(thisrule.split("(")[1].split(")")[0]);
                const add = thisrule.split(" ").pop() === "above";
                const modifiedAge = add ? age + number : age - number;
                return modifiedAge >= min && modifiedAge <= max;
              }
              return false;
            })
            .some((r) => r === true);
        }
      }
      return false;
    },
    async handleAddNonPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.npteamListRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { _id, role } = this.addNP;
        let { availableNonPlayers, nonplayers } = this.squadAllocation;
        availableNonPlayers = await availableNonPlayers.filter((player) => {
          if (player._id === _id) {
            player.role = role.type;
          }
        });
        nonplayers.push(_id);
        this.newNP.push(_id);
        this.clearNonplayer();
      } catch (e) {
        this.$customError("Could not add Non-Player");
      }
    },
    removeFromNonPlayerTeamList(row) {
      this.$confirm(
        "Are you sure you want to remove this non-player from the squad list",
        "Remove Non-Player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(async () => {
          let { nonplayers } = this.squadAllocation;
          this.squadAllocation.nonplayers = await nonplayers.filter((np) => np != row._id);
          if (this.newNP.indexOf(row._id) > -1) {
            this.newNP = await this.newNP.filter((npId) => npId != row._id);
          }
        })
        .catch((e) => {});
    },
    roleFormatter(nonPlayer) {
      if (this.newNP.indexOf(nonPlayer._id) > -1) {
        return this.squadRoles.find((role) => role.type === nonPlayer.role).name;
      }
      const { nonplayerTeams } = nonPlayer;
      return nonplayerTeams && nonplayerTeams[0] && nonplayerTeams[0].squadRole
        ? this.squadRoles.find((role) => role.type === nonplayerTeams[0].squadRole).name
        : "-";
    },
    clearNonplayer() {
      this.$set(this.addNP, "_id", null);
      this.$set(this.addNP, "role", null);
    },
  },
  computed: {
    ageFilter: {
      get() {
        return this.teamAgeLvl;
      },
      set(val) {
        this.teamAgeLvl = val;
      },
    },
    teamMemberType() {
      const type = memberTypes.find((m) => m.type === this.currentTeam.memberType || m.name === this.currentTeam.memberType);
      return type ? type.name : null;
    },
    nonPlayerRoles() {
      return this.memberTypes.filter((p) => p.playing === false).map((p) => p.name);
    },
    availablePlayersFiltered() {
      let filteredAvailableP = [];
      const { nameFilter, ageFilter, genderFilter } = this;
      if (this.currentTeam?.orgtree) {
        const  _id  = this.currentTeam?.orgtree?.association?._id;
        const { availablePlayers, players } = this.squadAllocation;
        filteredAvailableP = availablePlayers.filter((player) => {
          const formatDOB = player.profile.dob.split("-");
          player["display_name"] = `${player.name} ${formatDOB[2]}/${formatDOB[1]}/${formatDOB[0]}`;

          if (players.includes(player._id)) return true;
          return (
            this.doGenderFilter(genderFilter, player) &&
            this.doAgeFilter(ageFilter, player) &&
            this.doNameFilter(nameFilter, player) &&
            this.doTypeFilter(this.currentTeam.memberType, player, _id)
          );
        });
      }
      let sortedArray = filteredAvailableP.sort((a, b) => a.name.localeCompare(b.name));
      return sortedArray;
    },
    availableNPlayersFiltered() {
      let filteredAvailableNP = [];
      const { nameFilter, roleFilter } = this;
      if (this.currentTeam.orgtree && this.currentTeam.orgtree.club) {
        const { _id } = this.currentTeam.orgtree.club;
        const { availableNonPlayers, nonplayers } = this.squadAllocation;
        filteredAvailableNP = availableNonPlayers.filter((player) => {
          const formatDOB = player.profile.dob.split("-");
          if (player.nonplayerTeams && player.nonplayerTeams[0].squadRole) {
            player["display_name"] = `${player.name} ${player.nonplayerTeams[0].squadRole}`;
          } else {
            player[
              "display_name"
            ] = `${player.name} ${formatDOB[2]}/${formatDOB[1]}/${formatDOB[0]}`;
          }

          if (nonplayers.includes(player._id)) return true;
          return (
            this.doRoleFilter(roleFilter, player, _id) && this.doNameFilter(nameFilter, player)
          );
        });
      }
      let sortedArrayNP = filteredAvailableNP.sort((a, b) => a.name.localeCompare(b.name));
      return sortedArrayNP;
    },
    squadPlayers() {
      return this.squadAllocation.availablePlayers.filter((x) =>
        this.squadAllocation.players.includes(x._id)
      );
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clubMember() {
      const { availableNonPlayers, nonplayers } = this.squadAllocation;
      const filteredNp = availableNonPlayers.filter((player) => {
        return nonplayers.indexOf(player._id) === -1;
      });
      return filteredNp;
    },
    currentSquad() {
      const { availableNonPlayers, nonplayers } = this.squadAllocation;
      const filteredNp = availableNonPlayers.filter((player) => {
        return nonplayers.indexOf(player._id) > -1;
      });
      return filteredNp;
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}

.go-back-button {
  float: left;
}

.team-detail {
  padding: 0.4rem;
}

.box-card {
  margin-top: 1rem;
  text-align: left;
}

.player-squad {
  margin-top: 2rem;
  text-align: center;
}

.update-squad-box {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.filter-box {
  padding-top: 2rem;
  padding-left: 3%;
  padding-bottom: 1rem;

  span {
    margin-right: 2rem;
  }

  .el-input {
    width: 195px;
    height: 40px;
  }

  .el-input,
  .el-select {
    margin-right: 3rem;
  }
}

.data-table {
  .captain-icon {
    color: green;
    font-size: 2rem;
  }

  .remove-icon {
    color: red;
    font-size: 2rem;
  }
}
</style>
