<template>
  <div class="pending-rego">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="ageLevel" label="Age Level">
            <el-select v-model="filters.ageLvl" filterable placeholder="Select an age level">
              <el-option
                clearable
                v-for="item in ageLevels.map(l => l.name)"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="gender" label="Gender">
            <el-select clearable v-model="filters.gender" placeholder="Select a gender">
              <el-option
                v-for="gender in ['Male', 'Female']"
                :label="gender"
                :key="gender"
                :value="gender"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="status" label="Status">
            <el-select clearable v-model="filters.status" placeholder="Select a status">
              <el-option
                v-for="status in ['Pending', 'Declined']"
                :label="status"
                :key="status"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="clearance <= roles.associationAdmin">
        <el-col :span="6">
          <el-form-item prop="club" label="Club">
            <el-select clearable v-model="filters.club" placeholder="Select a club">
              <el-option v-for="{ _id, name } in myclubs" :label="name" :key="_id" :value="_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, allSeasons, roles, ageLevels } from "../../../utils/constants";

export default {
  name: "HistoricalRegoReport",
  components: {
    ActionButtons
  },
  mounted() {
    const permissions = this.$store.getters["user/getPermissions"];
    const national = this.$store.getters["user/activeRole"].national_id;
    const pendingPerm = national === 32 ? permissions?.pendingRegistApp ?? false : true;
    if(pendingPerm === false && national === 32){
        this.$router.replace('/reports');
    }

    if (this.clearance <= roles.associationAdmin) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/clubs")
        .then(res => {
          this.myclubs = res.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    }
  },
  data() {
    return {
      filters: {
        fileType: "csv"
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        }
      },
      roles,
      ageLevels,
      myclubs: [],
      fileTypes: [{ name: "CSV", type: "csv", default: true }]
    };
  },
  computed: {
    seasons() {
      const seasonValues = [0].concat(allSeasons);
      return seasonValues.map(v => ({ name: v === 0 ? "All Seasons" : v.toString(), value: v }));
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    }
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "pending-rego",
            fileType: this.filters.fileType,
            filters: {
              ageLvl: this.filters.ageLvl,
              status: this.filters.status,
              gender: this.filters.gender,
              club: this.filters.club
            },
            meta: {
              timezone: moment.tz.guess()
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
