var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar",class:{ uat: _vm.uat }},[_c('el-row',{staticClass:"image-box"},[(_vm.logo)?_c('img',{staticClass:"image",attrs:{"src":_vm.logo},on:{"error":_vm.displayPlaceholder}}):_vm._e(),_c('h3',{staticClass:"current-entity"},[_vm._v(_vm._s(_vm.activeEntity))]),_c('h4',{staticClass:"current-role"},[_vm._v(_vm._s(_vm.activeRole))])]),_c('el-row',{staticClass:"menu-box"},[_c('el-col',{attrs:{"span":24}},[_c('el-menu',{key:_vm.componentKey,staticClass:"el-menu-sidebar",attrs:{"collapse":_vm.isCollapse,"default-active":_vm.defaultIndex,"router":true,"background-color":"#0D0D0D","text-color":"#fff","active-text-color":"#848484"}},[_c('el-menu-item',{attrs:{"index":"/"}},[_c('span',[_vm._v("Dashboard")])]),(!_vm.isDeveloper && !_vm.isValidAdmin)?_c('div',[(
              _vm.clearance !== _vm.roles.programAdmin &&
              _vm.clearance !== _vm.roles.gamedevregionAdmin &&
              _vm.clearance !== _vm.roles.clubProgramAdmin
            )?_c('el-menu-item-group',[_c('div',{staticClass:"menu-title",class:{ uat: _vm.uat }},[_vm._v("Member Management /")]),(_vm.clearance <= _vm.roles.clubAdmin)?_c('el-menu-item',{attrs:{"index":"/member-management"}},[_c('span',[_vm._v(" My Members")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/duplicate-members"}},[_c('span',[_vm._v("Duplicate Members")])]):_vm._e(),(
                (_vm.clearance <= _vm.roles.clubAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32 &&
                  _vm.clearance !== _vm.roles.associationAdmin) ||
                (_vm.clearance === _vm.roles.associationAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32 &&
                  (!_vm.permissions || !_vm.permissions.hideClearanceApp))
              )?_c('el-menu-item',{attrs:{"index":"/clearance-permit"}},[_c('span',[_vm._v("Clearances / Permits")])]):_vm._e(),(
                (_vm.clearance === _vm.roles.clubAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32) ||
                (_vm.clearance === _vm.roles.associationAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32 &&
                  (!_vm.permissions || !_vm.permissions.pendingRegistApp))
              )?_c('el-menu-item',{attrs:{"index":"/pending-registrations"}},[_c('span',[_vm._v("Pending Registrations")])]):_vm._e(),(
                (_vm.clearance === _vm.roles.clubAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32) ||
                (_vm.clearance === _vm.roles.associationAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32 &&
                  (!_vm.permissions || !_vm.permissions.eighteenMonthApp))
              )?_c('el-menu-item',{attrs:{"index":"/18-month-dispensation"}},[_c('span',[_vm._v("18 Month Dispensation")])]):_vm._e()],1):_vm._e(),_c('el-menu-item-group',[_c('div',{staticClass:"menu-title",class:{ uat: _vm.uat }},[_vm._v("Administration /")]),(
                _vm.clearance !== _vm.roles.superAdmin &&
                _vm.clearance !== _vm.roles.teamAdmin &&
                _vm.clearance !== _vm.roles.programAdmin &&
                _vm.clearance !== _vm.roles.clubProgramAdmin
              )?_c('el-menu-item',{attrs:{"index":_vm.myEntityPath}},[_c('span',[_vm._v("My "+_vm._s(_vm.myEntity))])]):_vm._e(),(
                _vm.clearance !== _vm.roles.teamAdmin &&
                _vm.clearance !== _vm.roles.programAdmin &&
                _vm.clearance !== _vm.roles.clubProgramAdmin
              )?_c('el-menu-item',{attrs:{"index":"/account-details"}},[_c('span',[_vm._v("Bank Account Details")])]):_vm._e(),(
                _vm.clearance !== _vm.roles.teamAdmin &&
                _vm.clearance !== _vm.roles.gamedevregionAdmin &&
                _vm.clearance !== _vm.roles.programAdmin &&
                _vm.clearance !== _vm.roles.clubProgramAdmin
              )?_c('el-menu-item',{attrs:{"index":"/registration-products"}},[_c('span',[_vm._v("Products")])]):_vm._e(),(
                (_vm.clearance <= _vm.roles.programAdmin || _vm.clearance === _vm.roles.clubProgramAdmin) &&
                _vm.clearance !== _vm.roles.teamAdmin
              )?_c('el-menu-item',{attrs:{"index":"/reports"}},[_c('span',[_vm._v("Reports")])]):_vm._e(),(
                [
                  _vm.roles.clubAdmin,
                  _vm.roles.associationAdmin,
                  _vm.roles.gamedevregionAdmin,
                  _vm.roles.programAdmin,
                  _vm.roles.clubProgramAdmin ].includes(_vm.clearance)
              )?_c('el-menu-item',{attrs:{"index":"/communications"}},[_c('span',[_vm._v("Communications")])]):_vm._e(),(_vm.clearance <= _vm.roles.clubAdmin || _vm.clearance === _vm.roles.gamedevregionAdmin)?_c('el-menu-item',{attrs:{"index":"/user-management"}},[_c('span',[_vm._v("User Management")])]):_vm._e(),(_vm.clearance === _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/program-settings"}},[_c('span',[_vm._v("Program Type Settings")])]):_vm._e(),(_vm.clearance === _vm.roles.clubAdmin && !_vm.isTFA)?_c('el-menu-item',{attrs:{"index":"/program-settings-request"}},[_c('span',[_vm._v("Request Program Type Settings")])]):_vm._e(),(
                _vm.clearance === _vm.roles.programAdmin ||
                _vm.clearance === _vm.roles.clubProgramAdmin ||
                _vm.clearance === _vm.roles.gamedevregionAdmin ||
                _vm.clearance === _vm.roles.superAdmin ||
                (_vm.clearance === _vm.roles.clubAdmin && !_vm.isTFA) ||
                (_vm.clearance === _vm.roles.associationAdmin && !_vm.isTFA) ||
                (_vm.clearance === _vm.roles.regionAdmin && !_vm.isTFA) ||
                (_vm.clearance === _vm.roles.stateAdmin && !_vm.isTFA)
              )?_c('el-menu-item',{attrs:{"index":"/programs"}},[_c('span',[_vm._v("Programs")])]):_vm._e()],1),_c('br'),(
              _vm.clearance !== _vm.roles.programAdmin &&
              _vm.clearance !== _vm.roles.gamedevregionAdmin &&
              _vm.clearance !== _vm.roles.clubProgramAdmin
            )?_c('el-menu-item-group',[_c('div',{staticClass:"menu-title",class:{ uat: _vm.uat }},[_vm._v("Competition Management /")]),(_vm.clearance === _vm.roles.superAdmin && !_vm.isTFA)?_c('el-menu-item',{attrs:{"index":"/competition-types"}},[_c('span',[_vm._v("Competition Types")])]):_vm._e(),(_vm.clearance === _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/comp-or-team-list-types"}},[(_vm.isTFA)?_c('span',[_vm._v("Competition Types")]):_vm._e(),(!_vm.isTFA)?_c('span',[_vm._v("Team List Types")]):_vm._e()]):_vm._e(),(_vm.clearance === _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/ladder-templates"}},[_c('span',[_vm._v("Ladder Templates")])]):_vm._e(),(_vm.clearance === _vm.roles.clubAdmin || _vm.clearance === _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/squad-management"}},[_c('span',[_vm._v("Squad Management")])]):_vm._e(),(_vm.clearance === _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/restrict-squad-management"}},[_c('span',[_vm._v("Restrict Squad Management")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/competitions"}},[_c('span',[_vm._v("Competitions")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/matches"}},[_c('span',[_vm._v("Matches")])]):_vm._e(),(
                (_vm.clearance <= _vm.roles.clubAdmin && _vm.isRefereeClub) ||
                (_vm.clearance <= _vm.roles.associationAdmin && _vm.isRefereeAssociation)
              )?_c('el-menu-item',{attrs:{"index":"/matches/referee-appointments"}},[_c('span',[_vm._v("Referee Appointments")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/match-grid"}},[_c('span',[_vm._v("Match Grid")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin && !_vm.isRefereeAssociation)?_c('el-menu-item',{attrs:{"index":"/match-scores"}},[_c('span',[_vm._v("Match Scores")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/ladders"}},[_c('span',[_vm._v("Ladders")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":this.$store.getters['user/activeRole'].national_id === 32
                  ? '/competition-cases'
                  : '/under-construction'}},[_c('span',[_vm._v("Competition Cases")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/draws"}},[_c('span',[_vm._v("Draw Builder")])]):_vm._e(),(_vm.clearance === _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/team-regrading"}},[_c('span',[_vm._v("Team Regrading")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin && false)?_c('el-menu-item',{attrs:{"index":"/championship-ladders"}},[_c('span',[_vm._v("Championship Ladders")])]):_vm._e(),(_vm.clearance == _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/expirations"}},[_c('span',[_vm._v("Expirations")])]):_vm._e()],1):_vm._e(),_c('br'),(
              _vm.clearance !== _vm.roles.programAdmin &&
              _vm.clearance !== _vm.roles.gamedevregionAdmin &&
              _vm.clearance !== _vm.roles.clubProgramAdmin
            )?_c('el-menu-item-group',[_c('div',{staticClass:"menu-title",class:{ uat: _vm.uat }},[_vm._v("Entities /")]),(_vm.clearance == _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/states"}},[_c('span',[_vm._v("States")])]):_vm._e(),(_vm.clearance <= _vm.roles.stateAdmin)?_c('el-menu-item',{attrs:{"index":"/regions"}},[_c('span',[_vm._v("Regions")])]):_vm._e(),(_vm.clearance <= _vm.roles.regionAdmin)?_c('el-menu-item',{attrs:{"index":"/associations"}},[_c('span',[_vm._v("Associations")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/clubs"}},[_c('span',[_vm._v("Clubs")])]):_vm._e(),(
                _vm.clearance === _vm.roles.clubAdmin ||
                _vm.clearance === _vm.roles.associationAdmin ||
                _vm.clearance === _vm.roles.superAdmin
              )?_c('el-menu-item',{attrs:{"index":"/teams"}},[_c('span',[_vm._v("Teams")])]):_vm._e(),(_vm.clearance == _vm.roles.superAdmin)?_c('el-menu-item',{attrs:{"index":"/game-dev-regions"}},[_c('span',[_vm._v("Game Dev Regions")])]):_vm._e(),(_vm.clearance <= _vm.roles.associationAdmin)?_c('el-menu-item',{attrs:{"index":"/venues"}},[_c('span',[_vm._v("Venues")])]):_vm._e()],1):_vm._e()],1):_vm._e(),(this.enableThirdPartyRoute && !_vm.isValidAdmin)?_c('el-menu-item-group',[_c('div',{staticClass:"menu-title",class:{ uat: _vm.uat }},[_vm._v("Third party /")]),_c('el-menu-item',{attrs:{"index":"/api-keys-management"}},[_c('span',[_vm._v(" Api Keys")])])],1):_vm._e(),_c('br'),_c('br'),_c('br')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }